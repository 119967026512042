<template>
  <div id="page-user-list">
    <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="bookings.data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="bookings.pagination.per_page"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @filter-changed="onFilterChanged"
        @filter-modified="onFilterModified"
      >
      </ag-grid-vue>
      <!-- End AgGrid Table -->
      <vs-pagination
        :total="bookings.pagination.last_page"        
        :value="bookings.pagination.current_page"
        @input="paginate({ page: $event })"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import loaderDivMixin from "../../../mixins/loaderDivMixin";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererStatus,
  },
  mixins: [loaderDivMixin],
  computed: {
    ...mapGetters({
      bookings: "booking/getBookings",
      search: "booking/getSearchObj",
    }),
    searchParams() {
      let params = new URLSearchParams();
      params.append("page", this.page ? this.page : 1);
      this.search.query.id != ""
        ? params.append("query[id]", this.search.query.id)
        : "";
      this.search.query.user != ""
        ? params.append("query[user]", this.search.query.user)
        : "";
      this.search.query.status != ""
        ? params.append("query[status]", this.search.query.status)
        : "";
      this.search.query.service_type != ""
        ? params.append("query[service_type]", this.search.query.service_type)
        : "";
      this.search.query.service_name != ""
        ? params.append("query[service_name]", this.search.query.service_name)
        : "";

      return params;
    },
  },
  methods: {
    ...mapActions("booking", [
      "fetchAndSetBookings",
      "setSearchParameters",
      "clearSearchParameters",
    ]),
    paginate(page) {
      this.page = page.page;
      this.fetchBookings();
    },
    onFilterChanged($event) {
      this.setParams($event);
      this.fetchBookings();
    },
    onFilterModified($event) {
      this.setParams($event);
      this.fetchBookings();
    },
    setParams($event) {
      let $this = this;
      const searchKeys = Object.keys($event.api.getFilterModel());
      console.log(searchKeys);
      if (searchKeys.length == 0) this.clearSearchParameters();
      searchKeys.forEach(function (key, val) {
        let keyVal = key;
        if (key === "user.full_name") {
          keyVal = "user";
        }
        if (key === "service_detail.name") {
          keyVal = "service_name";
        }
        $this.setSearchParameters({
          key: keyVal,
          value: $event.api.getFilterModel()[key].filter,
        });
      });
    },
    fetchBookings() {
      this.openLoading();
      this.fetchAndSetBookings({ query: this.searchParams })
        .then((res) => this.closeLoading())
        .catch((error) => this.closeLoading());
    },
  },
  data() {
    return {
      // AgGrid
      page: 1,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: "agNumberColumnFilter",
          width: 150,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Booked By",
          field: "user.full_name",
          filter: "agTextColumnFilter",
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Total (in USD)",
          field: "total_amount",
          width: 150,
        },
        {
          headerName: "Type",
          field: "service_type",
          filter: "agTextColumnFilter",
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Booked Service",
          field: "service_detail.name",
          filter: "agTextColumnFilter",
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
          // width: 150,
          cellRendererFramework: "CellRendererStatus",
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Actions",
          field: "transactions",
          // width: 150,
          cellRendererFramework: "CellRendererActions",
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
        CellRendererStatus,
      },
    };
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
