<template>
  <div :style="{ 'direction': $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      icon="EyeIcon"
      title="View Booking Detail"
      svgClasses="h-5 w-5 mr-4 hover:text-success cursor-pointer"
      @click="viewRecord"
    />
    <feather-icon
      icon="XSquareIcon"
      title="Cancel Booking"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      @click="confirmCancelRecord"
    />

    <feather-icon
      icon="Trash2Icon"
      title="Delete Booking"
      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import loaderMixin from "../../../../mixins/loaderMixin";

export default {
  mixins: [loaderMixin],
  name: "CellRendererActions",
  methods: {
    ...mapActions("booking", [
      "deleteBookingAction",
      "cancelBookingAction",
      "fetchAndSetBookings",
    ]),
    viewRecord() {
      this.$router.push("bookings/" + this.params.data.id + "/view");
    },
    confirmCancelRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Cancel Booking`,
        text: `You are about to cancel booking with ID "${this.params.data.id}"`,
        accept: this.cancelRecord,
        acceptText: "Yes",
      });
    },
    cancelRecord() {
      this.openLoading();
      this.cancelBookingAction({
        bookingId: this.params.data.id,
        data: { status: "cancelled" },
      }).then(() => this.showCancelSuccess());
    },
    showCancelSuccess() {
      let params = new URLSearchParams();
      params.append("page", this.page);
      this.fetchAndSetBookings({ query: params })
        .then(() => this.closeLoading())
        .catch((error) => this.closeLoading());
      this.$vs.notify({
        color: "success",
        title: "Booking Cancelled",
        position: "top-right",
        text: "The selected booking was successfully cancelled",
      });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${this.params.data.id}"`,
        accept: this.deleteRecord,
        acceptText: "Delete",
      });
    },
    deleteRecord() {
      this.openLoading();
      this.deleteBookingAction(this.params.data.id).then(() =>
        this.showDeleteSuccess()
      );
    },
    showDeleteSuccess() {
      let params = new URLSearchParams();
      params.append("page", this.page);
      this.fetchAndSetBookings({ query: params })
        .then(() => this.closeLoading())
        .catch((error) => this.closeLoading());
      this.$vs.notify({
        color: "success",
        title: "Booking Deleted",
        position: "top-right",
        text: "The selected booking was successfully deleted",
      });
    },
  },
};
</script>
